@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
.snip1368 {
font-family: 'Roboto', Arial, sans-serif;
position: relative;
overflow: hidden;
margin: 10px;
min-width: 350px;
max-width: 500px;
width: 100%;
color: #ffffff;
line-height: 1.4em;
border-radius: 40px 80px;
}
.snip1368 * {
-webkit-box-sizing: border-box;
box-sizing: border-box;
-webkit-transition: all 0.4s ease;
transition: all 0.4s ease;
}
.snip1368 img {
opacity: 1;
width: 100%;
vertical-align: top;
}
.snip1368 .h3 {
position: absolute;
top: 0%;
width: 100%;
background-color: #212121;
z-index: 1;
text-align: right;
padding: 30px 50px 0px;
margin: 0;
font-weight: 700;
-webkit-transform: translateY(-200%);
transform: translateY(-200%);
}
.snip1368 .h3:before {
position: absolute;
content: '';
top: 100%;
left: 0;
width: 0;
height: 0;
border-style: solid;
border-width: 0 600px 70px 0;
border-color: transparent #212121 transparent transparent;
}
.snip1368 figcaption {
padding: 5px 25px 15px;
position: absolute;
width: 100%;
z-index: 2;
bottom: 0%;
background-color: #141414;
-webkit-transform: translateY(200%);
transform: translateY(400%);
}
.snip1368 figcaption:before {
position: absolute;
content: '';
bottom: 100%;
left: 0;
width: 0;
height: 0;
border-style: solid;
border-width: 50px 0 0 600px;
border-color: transparent transparent transparent #141414;
}
.snip1368 .icons {
margin-top: -0px;
}
.snip1368 i {
padding: 0px 8px;
display: inline-block;
font-size: 24px;
color: #ffffff;
text-align: center;
opacity: 0.7;
}
.snip1368 i:hover {
opacity: 1;
-webkit-transition: all 0.35s ease;
transition: all 0.35s ease;
}
.snip1368:hover .h3,
.snip1368.hover .h3 {
-webkit-transform: translateY(0%);
transform: translateY(0%);
}
.snip1368:hover figcaption,
.snip1368.hover figcaption {
-webkit-transform: translateY(0%);
transform: translateY(0%);
}