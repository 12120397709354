html,
body {
position: relative;
height: 100%;
}

body {
background: #000;
font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
font-size: 14px;
color: #000;
margin: 0;
padding: 0;
border: "1px solid white";
}

.swiper {
width: 100%;
height: 450px;
border: "1px solid white";
}

.swiper-slide {
text-align: center;
font-size: 18px;
background: #fff;

  /* Center slide text vertically */
display: flex;
justify-content: center;
align-items: center;
border: "1px solid white";
}

.swiper-slide img {
display: block;
width: 100%;
height: 100%;
object-fit: cover;
}

/* Mobil */
.mobileswiper {
width: 100%;
height: 320px;
}

.mobileswiper-slide {
text-align: center;
font-size: 15px;
background: #fff;

  /* Center slide text vertically */
display: flex;
justify-content: center;
align-items: center;
}

.mobileswiper-slide img {
display: block;
width: 100%;
height: 100%;
object-fit: cover;
}