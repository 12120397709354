@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);
.snip1200 {
/* font-family: 'Raleway', Arial, sans-serif; */
position: relative;
overflow: hidden;
margin: 10px;
min-width: 220px;
max-width: 300px;
max-height: 250px;
width: 100%;
background: #000;
/* color: #ffffff; */
text-align: center;
box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
font-size: 24px;
/* border: 1px solid white; */
/* background: rgba(22, 22, 22, 0.3); */
/* filter: "brightness(60%)"; */
}

.snip1200 * {
-webkit-box-sizing: border-box;
box-sizing: border-box;
-webkit-transition: all 0.45s ease-in-out;
transition: all 0.45s ease-in-out;
}

.snip1200 img {
max-width: 100%;
position: relative;
/* filter:blur(1px); */
}

.snip1200 figcaption {
position: absolute;
top: 45%;
left: 7%;
right: 7%;
bottom: 40%;
/* border: 1px solid ; */
/* border-width: 0px 1px 0px 1px; */
/* background: rgba(22, 22, 22, 0.2); */
}

.snip1200 .heading {
overflow: hidden;
-webkit-transform: translateY(50%);
transform: translateY(50%);
position: absolute;
bottom: 0;
width: 100%;
background:transparent;
/* background: rgba(22, 22, 22, 0.1); */
}

.snip1200 h2 {
display: table;
margin: 0 auto;
padding: 0 10px;
position: relative;
text-align: center;
width: auto;
text-transform: uppercase;
font-weight: 400;
background:transparent;
min-width: 220px;
max-width: 300px;
max-height: 250px;
/* background: rgba(22, 22, 22, 0.2) */
}

.snip1200 h2 span {
font-weight: 700;
color: #009eb7;
/* color: #EF9F5B; */
/* color: white; */
margin-bottom: 50;
}

.snip1200 h2:before,
.snip1200 h2:after {
position: absolute;
display: block;
width: 1000%;
height: 1px;
content: '';
/* background: white; */
top: 50%;
background:transparent;
}

.snip1200 h2:before {
left: -1000%;
/* background:transparent; */
}

.snip1200 h2:after {
right: -1000%;
}

.snip1200 p {
top: 50%;
-webkit-transform: translateY(-50%);
transform: translateY(-50%);
position: absolute;
width: 100%;
padding: 0 20px;
margin: 0;
opacity: 0;
line-height: 1.6em;
font-size: 0.7em;
color: white;
/* background:transparent; */
}

.snip1200 a {
left: 0;
right: 0;
top: 0;
bottom: 0;
position: absolute;
z-index: 1;
}

.snip1200:hover img,
.snip1200.hover img {
opacity: 0.25;
-webkit-transform: scale(1.1);
transform: scale(1.1);
}

.snip1200:hover figcaption,
.snip1200.hover figcaption {
top: 7%;
bottom: 7%;
}

.snip1200:hover p,
.snip1200.hover p {
opacity: 1;
-webkit-transition-delay: 0.35s;
transition-delay: 0.35s;
}

/* Mbile */

@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);
.mobilesnip1200 {
font-family: 'Raleway', Arial, sans-serif;
position: relative;
overflow: hidden;
margin: 10px;
min-width: 1000px;
max-width: 200px;
max-height: 200px;
width: 100%;
background: #000;
color: #ffffff;
text-align: center;
/* box-shadow: 0 0 5px rgba(0, 0, 0, 0.15); */
font-size: 16px;
/* border: 1px solid white; */
}

.mobilesnip1200 * {
-webkit-box-sizing: border-box;
box-sizing: border-box;
-webkit-transition: all 0.45s ease-in-out;
transition: all 0.45s ease-in-out;
}

.mobilesnip1200 .heading {
overflow: hidden;
-webkit-transform: translateY(50%);
transform: translateY(50%);
position: absolute;
bottom: 0;
width: 100%;
}

.mobilesnip1200 h2 {
display: table;
margin: 0 auto;
padding: 0 10px;
position: relative;
text-align: center;
width: auto;
text-transform: uppercase;
font-weight: 400;
}

.mobilesnip1200 h2 span {
font-weight: 800;
color: #ffffff;
}

.mobilesnip1200:hover img,
.mobilesnip1200.hover img {
opacity: 0.25;
-webkit-transform: scale(1.1);
transform: scale(1.1);
}
