/* company */
.content{
    max-width: 750px;
    margin: 0 auto;
}
.flexbox{
    display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
    flex-wrap: wrap;
    
}
.card{
	background-color: #ffffff;
	width: 200px;
    min-height: 200px;
    flex-basis: 150px;
    flex-grow: 1;
	box-shadow: 5px 5px 5px 1px darkgrey;
}

/* business */
.business-cardbox {
	flex-wrap: wrap;
	margin: 0 auto;
}

.business-card {
	background-color: #ffffff;
	max-width: 250px;
    max-height: 300px;
    flex-basis: 150px;
    flex-grow: 1;
	box-shadow: 5px 5px 5px 5px lightgray;
}

.businesshover{
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.businesshover:hover {
	-webkit-transform: scale(1.2);
    transform: scale(1.2);
}


strong {
	font-weight: bold;
}


.intro-block .monitor {
	border: 2px solid black;
	padding: 0;
	max-width: 100%;
	border-radius: 5px;
	background-color: black;
	margin: 0 auto;
}
.intro-block svg {
	width: 100%;
	margin-left: 20px;
	margin-right: 20px;
	position: relative;
	transition: all 0.5s ease-in-out;
}

.intro-block svg .ekg {
	fill: none;
	stroke: #00FFFF;
	stroke-width: 3;
	stroke-linecap: round;
	stroke-linejoin: miter;
	opacity: 0;
	stroke-dashoffset: 1000;
	stroke-dasharray: 1000;
	animation: ekg 1.5s linear forwards infinite;
}


@keyframes ekg {
	0% {
opacity: 0;
	}
	25% {
opacity: 1;
	}
	50% {
stroke-dashoffset: 2000;
	}
	99% {
opacity: 0;
stroke-dashoffset: 2000;
	}
	100% {
stroke-dashoffset: 1000;
	}
}


.section {
	height: 100vh;
}

.scrollspy {
	position: relative;
	z-index: 1; /* z-index를 낮춰 메뉴 바보다 뒤에 위치하도록 조정 */
	background-color: rgba(52, 52, 52, 0.8);
	margin-top: 400px;
	position: fixed;
	top: 50px; /* 메뉴 바 높이에 맞춰 top 위치 조정 */
	right: 0;
}
ul {
	list-style: none;
}

li {
	margin: 10px;
	padding: 10px 0;
}
a {
	color: white;
	text-decoration-line: none;
	font-weight: bold;
}
.is-current {
	font-weight: bold;
}

.is-current a {
	color: #509c9d;
}

.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.6);
}

.loginModal {
	width: 480px;
	height: 621px;
	background-color: white;
	position: relative;
	box-sizing: border-box;
	margin: 50px auto;
	padding: 20px;
	background: #fff;
}

.close {
	float: right;
	font-size: 25px;
}

.modalContents {
	margin: 0 auto;
	width: 100%;
	position: relative;
	padding: 0 20px 32px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.signinIcon {
	width: 150px;
	margin: 0 auto;
}

.loginId {
	margin-top: 30px;
	border-radius: 2px;
	width: 100%;
	height: 40px;
	border: 1px solid #e5e5e5;
	padding: 9px 12px;
	outline: none;
	box-sizing: border-box;
}

input::placeholder {
	color: #999999;
}

.loginPw {
	margin-top: 15px;
	border-radius: 2px;
	width: 100%;
	height: 40px;
	border: 1px solid #e5e5e5;
	padding: 9px 12px;
	outline: none;
	box-sizing: border-box;
}

.loginMid {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.autoLogin {
	font-size: 12px;
	color: #8d8d8d;
	line-height: 3;
}

.loginBtn {
	height: 40px;
	font-size: 14px;
	padding: 13px 30px;
	cursor: pointer;
	background-color: black;
	color: white;
	line-height: 1px;
	margin-top: 20px;
	margin-bottom: 12px;
	border-radius: 3px;
	border-style: none;
}

.socialBox {
	margin-bottom: 30px;
}

.kakao {
	background-color: #feec34;
	border-color: #feec34;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	margin-bottom: 10px;
	border-radius: 3px;
}

.kakaoLogo {
	width: 24px;
	height: 25px;
}

.kakaoText {
	width: 300px;
	font-size: 15px;
	text-align: center;
	display: inline-block;
	box-sizing: border-box;
}

.facebook {
	background-color: #21538a;
	border-color: #21538a;
	height: 40px;
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	color: #fff;
	border-radius: 3px;
}

.facebookText {
	padding-top: 12px;
	width: 310px;
	color: #fff;
	font-size: 15px;
	text-align: center;
	box-sizing: border-box;
}

.facebookLogo {
	padding-top: 7px;
	width: 24px;
	height: 25px;
}

.loginEnd {
	text-align: center;
	font-size: 11px;
}

.loginLine {
	color: #bcbcbc;
	font-size: 11px;
	margin-bottom: 35px;
}

.loginLine a {
	color: black;
	text-decoration: underline;
	cursor: pointer;
}

.noUser {
	text-decoration: underline;
}

/* footer */

/* bioscanpage */

/* swiperY paginationNames */

/* 3 */
/* h1 {
	position: relative;
	text-align: center;
	color: #353535;
	font-size: 50px;
	font-family: "Cormorant Garamond", serif;
} */

/* p {
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	text-align: center;
	font-size: 18px;
	color: #676767;
} */
/* .frame {
	width: 90%;
	margin: 40px auto;
	text-align: center;
} */

/* button {
	margin: 20px;
} */
.custom-btn {
	/* width: 100px; */
	/* height: 20px; */
	color: #fff;
	border-radius: 5px;
	padding: 10px 25px;
	font-family: 'Lato', sans-serif;
	/* font-weight: 500; */
	background: transparent;
	/* cursor: pointer; */
	/* transition: all 0.3s ease; */
	position: relative;
	display: inline-block;
	/* box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
	7px 7px 20px 0px rgba(0,0,0,.1),
	4px 4px 5px 0px rgba(0,0,0,.1); */
	outline: none;
}

/* .btn-3 {
	background: rgb(0,172,238);
	background: linear-gradient(0deg, rgba(0,172,238,1) 0%, rgba(2,126,251,1) 100%);
	width: 95px;
	height: 30px;
	line-height: 32px;
	padding: 0;
	border: none;
} */

/* .btn-3 span {
	position: relative;
    display: block;
    width: 100%;
    height: 100%;
} */
.btn-3:before,
.btn-3:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    /* background: rgba(2,126,251,1); */
    background: "black";
    transition: all 0.3s ease;
}
.btn-3:before {
	background: rgba(2,126,251,1);
    height: 0%;
    width: 2px;
}
.btn-3:after {
	background: rgba(2,126,251,1);
    width: 0%;
    height: 2px;
}
.btn-3:hover{
    background: transparent;
    box-shadow: none;
}
.btn-3:hover:before {
    height: 100%;
}
.btn-3:hover:after {
    width: 100%;
}
.btn-3 span:hover{
    /* color: rgba(2,126,251,1); */
    color: "black";
}
.btn-3 span:before,
.btn-3 span:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    /* background: rgba(2,126,251,1); */
    background: "black";
    transition: all 0.3s ease;
}
.btn-3 span:before {
	background: rgba(2,126,251,1);
    width: 2px;
    height: 0%;
}
.btn-3 span:after {
	background: rgba(2,126,251,1);
    width: 0%;
    height: 2px;
}
.btn-3 span:hover:before {
    height: 100%;
}

.btn-3 span:hover:after {
    width: 100%;
}

.social-footer {
	padding: 1rem;
	background: #8a8a8a;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
		-ms-flex-align: center;
			align-items: center;
	-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
			justify-content: space-between;
}

.social-footer .social-footer-icons li:last-of-type {
	margin-right: 0;
}

.social-footer .social-footer-icons .fa {
	font-size: 1.3rem;
	color: #fefefe;
}

.social-footer .social-footer-icons .fa:hover {
	color: #4a4a4a;
	transition: color 0.3s ease-in;
}
