:root {
    --button_font_color: white;
    --button_font_size: 1em;
    --border_size: 0.1rem;
    --border_top: -0.1rem;
    --animation_duration: 200ms;
    --animation_duration_half: 100ms;
} 

.swiper_button {
    position: relative;
    padding: 8px;
    cursor: pointer;
    border: none;
    color: var(--button_font_color);
    font-size: var(--button_font_size);
    font-weight: 600;
    background: transparent;
    text-align: right;
    white-space: pre-wrap;
}

.swiper_button:before, .swiper_button:after {
    display: block;
    content: "";
    border: none;
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.swiper_button:before {
    top: var(--border_top);
    left: 0;
}

.swiper_button:after {
    bottom: 0;
    right: 0;
}

.swiper_button.swiper_button_active:before {
    width: 100%;
    height: 100%;
    opacity: 1;
    border-top: var(--border_size) solid white;
    border-right: var(--border_size) solid white;
    transition: width var(--animation_duration) cubic-bezier(.07,.62,.61,1), height var(--animation_duration_half) var(--animation_duration) cubic-bezier(.07,.62,.61,1);
}

.swiper_button.swiper_button_active:after {
    width: 100%;
    height: 100%;
    opacity: 1;
    border-bottom: var(--border_size) solid white;
    border-left: var(--border_size) solid white;
    transition: width var(--animation_duration) cubic-bezier(.07,.62,.61,1), height 150ms var(--animation_duration) cubic-bezier(.07,.62,.61,1);
}
